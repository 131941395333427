document.addEventListener("DOMContentLoaded", function () {
    tinymce.init({
        selector: '.content-field',
        height: 200,
        menubar: false,
        forced_root_blocks: false,
        convert_fonts_to_spans : false,
        plugins: [
            'anchor'
        ],
        toolbar: 'undo redo | casechange fontfamily fontsize blocks | bold italic forecolor backcolor | removeformat',
    });
});